import React from 'react';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import {
  GetInspectionDto,
  GetInspectedProductDto,
  CreateUpdateInspectionDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import RichTextEdit from '../../../app/components/RichTextEdit';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

interface InspectionProtocolsProps {
  inspection: GetInspectionDto;
  products?: GetInspectedProductDto[];
  protocols: AssociateDocuments<
    ProtocolDuringInspectionContext.ProtocolWithAttachments,
    ProtocolDuringInspectionContext.CreateUpdateProtocolWithAttachments
  >;
  readonly: boolean;
  changeRichText: (obj: Partial<CreateUpdateInspectionDto>) => void;
}

export const InspectionProtocols: React.FC<InspectionProtocolsProps> =
  props => {
    const generateProtocolsList = () => {
      let listString = '';
      props.protocols.documents.forEach(doc => {
        let name = `Protokół <em>${props.protocols.labelGenerator(doc)}</em>`;
        if (doc.attachments.length) {
          name += ` wraz z załącznikami (liczba załączników: <em>${doc.attachments.length}</em>)`;
        }
        listString += `<li>${name}</li>`;
      });
      props.changeRichText({ protocols: `<ul>${listString}</ul>` });
    };

    if (!props.products) {
      return <div>No products</div>;
    } else {
      return (
        <>
          {!props.readonly && (
            <div className="d-flex justify-content-end mb-3">
              <ConfirmationButton
                variant="outline-success"
                roleAnyOf={[Role.InspectionsEdit]}
                onOK={generateProtocolsList}
                confirmation="Czy na pewno chcesz wygenerować listę aktualnie wprowadzonych protokołów (dotychczasowe dane w polu tekstowym zostaną usunięte)?"
              >
                Generuj listę protokołów
              </ConfirmationButton>
            </div>
          )}
          <RichTextEdit
            value={props.inspection.protocols}
            readonly={props.readonly}
            onChange={value => props.changeRichText({ protocols: value })}
          />
        </>
      );
    }
  };
