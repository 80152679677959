/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineEventType
 */
export interface TimelineEventType {
    /**
     * 
     * @type {Date}
     * @memberof TimelineEventType
     */
    dateOfEvent?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventType
     */
    organizationalUnit: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventType
     */
    event: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventType
     */
    objectType: TimelineEventTypeObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventType
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventType
     */
    rootId: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventType
     */
    middleId: string;
}

export function TimelineEventTypeFromJSON(json: any): TimelineEventType {
    return TimelineEventTypeFromJSONTyped(json, false);
}

export function TimelineEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineEventType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateOfEvent': !exists(json, 'dateOfEvent') ? undefined : (json['dateOfEvent'] === null ? null : new Date(json['dateOfEvent'])),
        'organizationalUnit': json['organizationalUnit'],
        'event': json['event'],
        'objectType': json['objectType'],
        'objectId': json['objectId'],
        'rootId': json['rootId'],
        'middleId': json['middleId'],
    };
}

export function TimelineEventTypeToJSON(value?: TimelineEventType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateOfEvent': value.dateOfEvent === undefined ? undefined : (value.dateOfEvent === null ? null : value.dateOfEvent.toISOString()),
        'organizationalUnit': value.organizationalUnit,
        'event': value.event,
        'objectType': value.objectType,
        'objectId': value.objectId,
        'rootId': value.rootId,
        'middleId': value.middleId,
    };
}

/**
* @export
* @enum {string}
*/
export enum TimelineEventTypeObjectTypeEnum {
    InspectedProduct = 'InspectedProduct',
    SampleExam = 'SampleExam',
    Inspection = 'Inspection',
    Metric = 'Metric',
    JudgmentFromInspection = 'JudgmentFromInspection',
    JudgmentFromProceeding = 'JudgmentFromProceeding',
    JudgmentFromPak = 'JudgmentFromPak',
    JudgmentFromSampleExam = 'JudgmentFromSampleExam',
    Proceeding = 'Proceeding',
    Pak = 'Pak',
    Sample = 'Sample',
    ExamResult = 'ExamResult',
    CorrectiveActionFromInspection = 'CorrectiveActionFromInspection',
    CorrectiveActionFromProceeding = 'CorrectiveActionFromProceeding'
}


