/*eslint max-lines-per-function: ["error", 170]*/
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import {
  CreateUpdateAttachmentDto,
  CreateUpdateJudgmentDto,
  CreateUpdateSampleDtoFromJSON,
  GetAttachmentDto,
  GetJudgmentDto,
  GetPakDto,
  GetSampleCollectDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import {
  SampleExamApi,
  SampleWithResult,
} from '../../../common/snrwbCore/contexts/SampleExamContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import FormSkeleton from '../../../app/components/FormSkeleton';

import { SampleDetails } from './SampleDetails';

export const Samples = (props: {
  loading: boolean;
  examApproved: boolean;
  editAuthorizedWithoutCompl: boolean;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  samples: SampleWithResult[];
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  paks: GetPakDto[];
  openWithSampleId?: string;
  openWithBannerId?: string;
  api: SampleExamApi;
  onBannerClosed: () => void;
  completionDate: Date | null | undefined;
  sampleCollect: GetSampleCollectDto;
}) => {
  const [activeItem, setActiveItem] = useState<string>();
  const addSample = () => {
    const sample = CreateUpdateSampleDtoFromJSON({
      controlSample: props.samples.length === 1,
    });
    props.api.addSample(sample);
  };
  const mayBeDeleted = () => {
    if (!activeItem || props.examApproved) {
      return false;
    }
    const activeSample = props.samples.filter(s => s.sample.id === activeItem);
    if (activeSample.length === 0) {
      return false;
    }

    if (!activeSample[0].sample.controlSample && props.samples.length > 1) {
      return false;
    }

    return true;
  };

  const castAttachments = (sampleId: string) => {
    return props.attachments.filter(
      attachment => attachment.objectId === sampleId,
      attachment => {
        attachment.enObjectType = AttachmentContext.ObjectType.Sample;
        attachment.objectId = sampleId;
      },
    );
  };
  const castJudgments = (sampleId: string) => {
    return props.judgments.filter(
      judgment => judgment.sampleId === sampleId,
      judgment => (judgment.sampleId = sampleId),
    );
  };

  useEffect(() => {
    if (activeItem) {
      return;
    }
    if (props.samples.length > 0) {
      const samples = props.samples.filter(s => !s.sample.controlSample);
      if (samples.length === 1) {
        setActiveItem(samples[0].sample.id);
      }
    }
  }, [props.samples, activeItem]);

  useEffect(() => {
    if (props.openWithSampleId) {
      setActiveItem(props.openWithSampleId);
    }
  }, [props.openWithSampleId]);

  return (
    <>
      {props.samples.length === 0 ? (
        props.loading ? (
          <FormSkeleton />
        ) : (
          <Alert variant="warning">
            W badaniu nie ma jeszcze żadnej próbki.
          </Alert>
        )
      ) : (
        <Accordion className="mb-3" activeKey={activeItem}>
          {props.samples.map(sr => (
            <Accordion.Item key={sr.sample.id} eventKey={sr.sample.id}>
              <Accordion.Header
                onClick={() => {
                  setActiveItem(
                    sr.sample.id === activeItem ? 'non-existing' : sr.sample.id,
                  );
                }}
              >
                {sr.sample.controlSample ? 'Próbka kontrolna' : 'Próbka'}
              </Accordion.Header>
              <Accordion.Body>
                <SampleDetails
                  examApproved={props.examApproved}
                  editAuthorizedWithoutCompl={props.editAuthorizedWithoutCompl}
                  editAuthorized={props.editAuthorized}
                  publishAuthorized={props.publishAuthorized}
                  value={sr}
                  paks={props.paks}
                  attachments={castAttachments(sr.sample.id)}
                  judgments={castJudgments(sr.sample.id)}
                  bannerId={props.openWithBannerId}
                  onBannerClosed={props.onBannerClosed}
                  onChange={dto => props.api.updateSample(sr.sample.id, dto)}
                  onAddResult={() => props.api.addResult(sr.sample.id)}
                  onUpdateResult={dto =>
                    props.api.updateResult(sr.result.id, dto)
                  }
                  onApproveResult={() => props.api.approveResult(sr.result.id)}
                  onRevertApproveResult={reason =>
                    props.api.revertApproveResult(sr.result.id, reason)
                  }
                  mayBeApprovedResult={() =>
                    props.api.mayBeApprovedResult(sr.result.id)
                  }
                  mayBePublishedResult={() =>
                    props.api.mayBePublishedResult(sr.result.id)
                  }
                  onDeleteResult={() => props.api.deleteResult(sr.result.id)}
                  onRevertExam={() =>
                    props.api.revertControlSampleExam(
                      sr.proceeding?.id || 'not-ok',
                    )
                  }
                  onAddPak={() => props.api.addPak(sr.sample.id)}
                  onPublishResult={() => props.api.publishResult(sr.result.id)}
                  onRevertPublishResult={() =>
                    props.api.revertPublishResult(sr.result.id)
                  }
                  onResultDoc={() =>
                    props.api.generateResultDocument(sr.sample.id)
                  }
                  completionDate={props.completionDate}
                  sampleCollect={props.sampleCollect}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
      {!props.examApproved && props.editAuthorized && props.samples.length < 2 && (
        <Button variant="outline-primary" onClick={addSample}>
          {props.samples.length === 0
            ? 'Dodaj próbkę'
            : 'Dodaj próbkę kontrolną'}
        </Button>
      )}
      {activeItem && mayBeDeleted() && props.editAuthorized && (
        <ConfirmationButton
          className="mx-3"
          roleAnyOf={[Role.ExamsEdit]}
          confirmation="Czy na pewno usunąć próbkę?"
          variant="outline-danger"
          onOK={() => {
            props.api.deleteSample(activeItem);
            setActiveItem(undefined);
          }}
        >
          Usuń próbkę
        </ConfirmationButton>
      )}
    </>
  );
};
