/*eslint complexity: ["error", 14]*/
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import _ from 'lodash';

import {
  CreateUpdateSampleDto,
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
  GetJudgmentDto,
  CreateUpdateJudgmentDto,
  CreateUpdateExamResultDto,
  ValidationStatus,
  GetPakDto,
  GetSampleCollectDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as SampleContext from '../../../common/snrwbCore/contexts/SampleContext';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Judgment } from '../Judgments/Judgment';
import { SampleWithResult } from '../../../common/snrwbCore/contexts/SampleExamContext';
import { useNavigation } from '../../../common/navigation';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import momencik from '../../../common/momencik';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import SampleBeforeSigning from './SampleBeforeSigning';
import SampleAfterSigning from './SampleAfterSigning';
import SampleResult from './SampleResult';

const SampleDetailsProceedingAlert: React.FC<{
  editAuthorized: boolean;
  value: SampleWithResult;
  onRevertExam: () => void;
}> = props => {
  const nav = useNavigation();
  return (
    <>
      {props.value.proceeding && (
        <Alert
          variant="warning"
          className="d-flex justify-content-between align-items-center"
        >
          <div>Próbka kontrolna zlecona w postępowaniu</div>
          <div>
            {props.editAuthorized && !props.value.result?.approved && (
              <ConfirmationButton
                confirmation="Czy na pewno cofnąć zlecenie?"
                roleAnyOf={[Role.ExamsEdit]}
                onOK={props.onRevertExam}
                variant="outline-danger"
                className="mx-1"
              >
                Cofnij
              </ConfirmationButton>
            )}
            <Button
              variant="outline-primary"
              onClick={() => {
                if (props.value.proceeding) {
                  nav.proceeding(props.value.proceeding.id);
                }
              }}
            >
              Postępowanie
            </Button>
          </div>
        </Alert>
      )}
    </>
  );
};

export const SampleDetails: React.FC<{
  examApproved: boolean;
  editAuthorizedWithoutCompl: boolean;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  value: SampleWithResult;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  paks: GetPakDto[];
  bannerId?: string;
  onChange: (sample: CreateUpdateSampleDto) => void;
  onAddResult: () => void;
  onUpdateResult: (dto: CreateUpdateExamResultDto) => void;
  onApproveResult: () => void;
  onRevertApproveResult: (reason?: string) => void;
  mayBeApprovedResult: () => Promise<ValidationStatus>;
  mayBePublishedResult: () => Promise<ValidationStatus>;
  onDeleteResult: () => void;
  onRevertExam: () => void;
  onAddPak: () => void;
  onPublishResult: () => void;
  onRevertPublishResult: () => void;
  onResultDoc: () => void;
  onBannerClosed: () => void;
  completionDate: Date | null | undefined;
  sampleCollect: GetSampleCollectDto;
}> = props => {
  const nav = useNavigation();
  const [sample, setSample] = useState(
    SampleContext.toCuDto(props.value.sample),
  );
  const [presentedSample, setPresentedSample] = useState(sample);
  const [readonlyCost, setReadonlyCost] = useState(
    (!props.value.result &&
      props.completionDate !== undefined &&
      props.completionDate !== null) ||
      (props.value.result?.publicationDate !== undefined &&
        props.value.result?.publicationDate !== null) ||
      !props.editAuthorizedWithoutCompl,
  );
  const pak = props.paks.find(p => p.sample.id === props.value.sample.id);

  useEffect(() => {
    setSample(SampleContext.toCuDto(props.value.sample));
  }, [props.value]);
  useEffect(() => {
    setPresentedSample(sample);
  }, [sample]);
  useEffect(() => {
    setReadonlyCost(
      (!props.value.result &&
        props.completionDate !== undefined &&
        props.completionDate !== null) ||
        (props.value.result?.publicationDate !== undefined &&
          props.value.result?.publicationDate !== null) ||
        !props.editAuthorizedWithoutCompl,
    );
  }, [props.value, props.editAuthorizedWithoutCompl, props.completionDate]);

  const setProperty = (obj: Partial<CreateUpdateSampleDto>) => {
    setPresentedSample({ ...presentedSample, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleDto) => {
    if (_.isEqual(sample, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const sampleChange = (obj: Partial<CreateUpdateSampleDto>) => {
    const dto = { ...sample, ...obj };
    setSample(dto);
    saveIfChanged(dto);
  };
  const presentedToSelected = () => {
    setSample(presentedSample);
    saveIfChanged(presentedSample);
  };

  const changeAndBlur = (obj: Partial<CreateUpdateSampleDto>) => {
    setPresentedSample({ ...presentedSample, ...obj });
    setSample({ ...presentedSample, ...obj });
    saveIfChanged({ ...presentedSample, ...obj });
  };

  return (
    <>
      <SampleDetailsProceedingAlert {...props} />
      {!props.examApproved ? (
        <SampleBeforeSigning
          editAuthorized={props.editAuthorized}
          presentedSample={presentedSample}
          setProperty={setProperty}
          presentedToSelected={presentedToSelected}
          changeAndBlur={changeAndBlur}
          sampleChange={sampleChange}
          operateOrganizationalUnit={
            props.value?.sample?.operateOrganizationalUnit
          }
          collectPlace={props.sampleCollect.collectPlace}
          examOrganizationalUnitName={
            props.sampleCollect.examOrganizationalUnit.name
          }
          storagePlaceId={props.value.sample.storagePlaceId}
        />
      ) : (
        <>
          {props.value.result && (
            <SampleResult
              value={props.value.result}
              editAuthorized={props.editAuthorized}
              publishAuthorized={props.publishAuthorized}
              attachment={props.value.resultAttachments}
              onUpdate={props.onUpdateResult}
              onApprove={props.onApproveResult}
              onRevertApprove={props.onRevertApproveResult}
              onPublish={props.onPublishResult}
              onRevertPublish={props.onRevertPublishResult}
              mayBeApproved={props.mayBeApprovedResult}
              mayBePublished={props.mayBePublishedResult}
              onDelete={props.onDeleteResult}
              onPdf={props.onResultDoc}
            />
          )}
          <SampleAfterSigning
            readonly={props.value.result?.approved || !props.editAuthorized}
            readonlyCosts={readonlyCost}
            presentedSample={presentedSample}
            sample={props.value.sample}
            setProperty={setProperty}
            presentedToSelected={presentedToSelected}
            sampleChange={sampleChange}
            operateOrganizationalUnit={
              props.value?.sample?.operateOrganizationalUnit
            }
            collectPlace={props.sampleCollect.collectPlace}
            examOrganizationalUnitName={
              props.sampleCollect.examOrganizationalUnit.name
            }
          />
        </>
      )}
      <div className="mt-3 d-flex justify-content-between">
        <div>
          {pak ? (
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (pak) {
                  nav.pak(pak.id);
                }
              }}
            >
              PAK z {momencik(pak.startDate)}
            </Button>
          ) : (
            props.editAuthorized && (
              <ConfirmationButton
                variant="outline-warning"
                roleAnyOf={[Role.PakEdit]}
                confirmation="Czy na pewno utworzyć postępowanie w sprawie nałożenia kary?"
                onOK={props.onAddPak}
              >
                Nowe PAK
              </ConfirmationButton>
            )
          )}
        </div>
        <div className="d-flex">
          <AssociatedDocuments
            name="Załączniki"
            readonly={!props.editAuthorized}
            createModalType={AddAttachment}
            presentModalType={Attachment}
            docs={props.attachments}
          />
          <AssociatedDocuments
            name="Orzeczenia"
            readonly={!props.editAuthorized}
            createModalType={Judgment}
            presentModalType={Judgment}
            docs={props.judgments}
            initialBannerId={props.bannerId}
            onBannerClosed={props.onBannerClosed}
            className="ms-1"
            data={JSON.stringify({
              firstLevelShortname: 'koszty',
              showAppeal: props.editAuthorized,
              deleteRole: Role.ExamsEdit,
            })}
          />
          {!props.value.result && props.examApproved && props.editAuthorized && (
            <Button
              variant="outline-primary"
              onClick={props.onAddResult}
              className="ms-1"
            >
              Dodaj wynik
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
