import React from 'react';

import { SnrwbCoreContextType } from '..';
import { AssociateDocuments } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { NotificationsProviderType } from '../../notifications';
import {
  CreateUpdateSampleExamDto,
  CreateUpdateOrganizationDto,
  GetSampleExamDto,
  DefaultApi,
  CreateUpdateProductTypeDto,
  CreateUpdateSampleExamDtoFromJSON,
  GetSampleDto,
  GetExamResultDto,
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
  ValidationStatus,
  CreateUpdateSampleDto,
  CreateUpdateEssentialFeatureDto,
  CreateUpdateExamResultDtoFromJSON,
  CreateUpdateExamResultDto,
  GetSampleExamExtendedDto,
  GetProceedingDto,
  GetExamResultDtoFromJSON,
  GetProceedingDtoFromJSON,
  GetInspectedProductDto,
  GetJudgmentDto,
  CreateUpdateJudgmentDto,
  GetPakDto,
  GetEssentialFeatureDto,
  GetInspectorDto,
  CreateUpdateInspectorDto,
  CreateUpdateSampleCollectDto,
  GetSampleExtendedDto,
  GetSampleExamViewModel,
} from '../autogenerated/snrwbApiClient';
import {
  AddInspectionFunc,
  handlerForAddInspection,
} from '../../../common/snrwbCore/contexts/InspectionContext';
import momencik from '../../momencik';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { sampleExamForGrid } from '../../../snrwb/components/SampleExams/SampleExamSearch';
import TextOrControl from '../../../app/components/TextOrControl';
import UnorderedList from '../../../app/components/UnorderedList';

import * as AttachmentContext from './AttachmentContext';
import * as InspectorContext from './InspectorContext';
import * as JudgmentContext from './JudgmentContext';

export interface SampleExamContextInterface {
  getById: (id: string) => Promise<GetSampleExamDto>;
  getViewModel: (id: string) => Promise<GetSampleExamViewModel>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getPublishedByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getPublishedCount: (filterText?: string) => Promise<number>;
  createBasedOnProductType: (
    sampleCollectId: string,
    productTypeId: string,
  ) => Promise<GetSampleExamDto>;
  createBasedOnProductTypeWithLinkedInspection: (
    sampleCollectId: string,
    productTypeId: string,
    productInspectionId: string,
  ) => Promise<GetSampleExamDto>;
  createWithNewProductType: (
    sampleCollectId: string,
    productType: CreateUpdateProductTypeDto,
  ) => Promise<GetSampleExamDto>;
  createWithNewProductTypeAndOrganization: (
    sampleCollectId: string,
    producer: CreateUpdateOrganizationDto,
    productType: CreateUpdateProductTypeDto,
  ) => Promise<GetSampleExamDto>;
  createBasedOnInspectedProduct: (
    dto: CreateUpdateSampleCollectDto,
    inspectedProductId: string,
  ) => Promise<GetSampleExamDto>;
  update: (id: string, sampleExam: CreateUpdateSampleExamDto) => Promise<void>;
  updateWithNewSupplier: (
    id: string,
    supplier: CreateUpdateOrganizationDto,
  ) => Promise<void>;
  getExamsInRevisionByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getExamsInRevisionCount: (filterText?: string) => Promise<number>;
  getNotInspectedForUser: (
    productTypeId: string,
  ) => Promise<GetSampleExamExtendedDto[]>;
  getReadyForPublicationByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getReadyForPublicationCount: (filterText?: string) => Promise<number>;
  getExamsWithoutApprovedResultsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getExamsWithoutApprovedResultsCount: (filterText?: string) => Promise<number>;
  getExamsWithoutApprovedResults(): Promise<GetSampleExamExtendedDto[]>;
  mayDelete: (id: string) => Promise<ValidationStatus>;
  delete: (id: string) => Promise<void>;
  mayFinish: (id: string) => Promise<ValidationStatus>;
  mayRevertSigning: (id: string) => Promise<ValidationStatus>;
  readyToSign: (id: string) => Promise<ValidationStatus>;
  sign: (id: string) => Promise<void>;
  revertSign: (id: string) => Promise<void>;
  getByInspectedProduct: (id: string) => Promise<GetSampleExamDto>;
}

export const SampleExamContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.sampleExamControllerGet(id),
    getViewModel: (id: string) => api.sampleExamControllerGetViewModel(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.sampleExamControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(sampleExamForGrid);
    },
    getAllCount: (filterText?: string) =>
      api.sampleExamControllerGetAllCount(filterText || ''),
    getPublishedByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.sampleExamControllerGetPublishedByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(sampleExamForGrid);
    },
    getPublishedCount: (filterText?: string) =>
      api.sampleExamControllerGetPublishedCount(filterText || ''),
    createBasedOnProductType: (
      sampleCollectId: string,
      productTypeId: string,
    ) =>
      api.sampleExamControllerCreateBasedOnProductType({
        sampleCollectId,
        productTypeId,
      }),
    createBasedOnProductTypeWithLinkedInspection: (
      sampleCollectId: string,
      productTypeId: string,
      productInspectionId: string,
    ) =>
      api.sampleExamControllerCreateBasedOnProductTypeWithLinkedInspection({
        sampleCollectId,
        productTypeId,
        productInspectionId,
      }),
    createWithNewProductType: (
      sampleCollectId: string,
      productType: CreateUpdateProductTypeDto,
    ) =>
      api.sampleExamControllerCreateWithNewProductType({
        sampleCollectId,
        productType,
      }),
    createWithNewProductTypeAndOrganization: (
      sampleCollectId: string,
      producer: CreateUpdateOrganizationDto,
      productType: CreateUpdateProductTypeDto,
    ) =>
      api.sampleExamControllerCreateWithNewProductTypeAndOrganization({
        sampleCollectId,
        producer,
        productType,
      }),
    createBasedOnInspectedProduct: (
      dto: CreateUpdateSampleCollectDto,
      inspectedProductId: string,
    ) =>
      api.sampleExamControllerCreateBasedOnInspectedProduct({
        sampleCollectDto: dto,
        inspectedProductId,
      }),
    update: (id: string, sampleExam: CreateUpdateSampleExamDto) =>
      api.sampleExamControllerUpdate(id, sampleExam),
    updateWithNewSupplier: (
      id: string,
      supplier: CreateUpdateOrganizationDto,
    ) => api.sampleExamControllerUpdateWithNewSupplier(id, supplier),
    getExamsInRevisionByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.sampleExamControllerGetExamsInRevisionByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(sampleExamForGrid);
    },
    getExamsInRevisionCount: (filterText?: string) =>
      api.sampleExamControllerGetExamsInRevisionCount(filterText || ''),
    getNotInspectedForUser: (productTypeId: string) =>
      api.sampleExamControllerGetNotInspectedForUser(productTypeId),
    getReadyForPublicationByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data =
        await api.sampleExamControllerGetReadyForPublicationByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
        );
      return data.map(sampleExamForGrid);
    },
    getReadyForPublicationCount: (filterText?: string) =>
      api.sampleExamControllerGetReadyForPublicationCount(filterText || ''),
    getExamsWithoutApprovedResultsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data =
        await api.sampleExamControllerGetExamsWithoutApprovedResultsByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
        );
      return data.map(sampleExamForGrid);
    },
    getExamsWithoutApprovedResultsCount: (filterText?: string) =>
      api.sampleExamControllerGetExamsWithoutApprovedResultsCount(
        filterText || '',
      ),
    getExamsWithoutApprovedResults: () =>
      api.sampleExamControllerGetExamsWithoutApprovedResults(),
    mayDelete: async (id: string) => api.sampleExamControllerMayDelete(id),
    delete: (id: string) => api.sampleExamControllerDelete(id),
    mayFinish: async (id: string) => api.sampleExamControllerMayFinish(id),
    mayRevertSigning: async (id: string) =>
      api.sampleExamControllerMayRevertSigning(id),
    readyToSign: (id: string) => api.sampleExamControllerReadyToSign(id),
    sign: (id: string) => api.sampleExamControllerSign(id),
    revertSign: (id: string) => api.sampleExamControllerRevertSign(id),
    getByInspectedProduct: (id: string) =>
      api.sampleExamControllerGetByInspectedProduct(id),
  };
};

export const toCuDto = (getDto: GetSampleExamDto) => {
  const cuDto = CreateUpdateSampleExamDtoFromJSON(getDto);
  if (getDto.organizationType) {
    cuDto.organizationTypeId = getDto.organizationType.id;
  }
  if (getDto.protocolPrintMode) {
    cuDto.protocolPrintModeId = getDto.protocolPrintMode.id;
  }
  if (getDto.supplier) {
    cuDto.supplierId = getDto.supplier.id;
  }
  if (getDto.productType) {
    cuDto.productTypeId = getDto.productType.id;
  }
  return cuDto;
};

export type SampleWithResult = {
  sample: GetSampleDto;
  proceeding?: GetProceedingDto;
  result: GetExamResultDto;
  resultAttachments: AssociateDocuments<
    GetAttachmentDto,
    CreateUpdateAttachmentDto
  >;
};

export type ModelGeneratorFunc = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => Promise<
  Partial<{
    exam: GetSampleExamDto;
    approvedExams: boolean;
    samples: SampleWithResult[];
    inspectedProducts: GetInspectedProductDto[];
    inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
    samplesAttachments: AssociateDocuments<
      GetAttachmentDto,
      CreateUpdateAttachmentDto
    >;
    judgments: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
    paks: GetPakDto[];
    features: GetEssentialFeatureDto[];
  }>
>;

const getSampleExam = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  sampleExamId: string,
  action: () => void,
) => {
  if (sampleExamId === 'yes, lint, I love you') {
    action();
  }

  const viewModel = await snrwb.sampleExams.getViewModel(sampleExamId);
  return { exam: viewModel.sampleExam, approvedExams: viewModel.approvedExams };
};

const getSamplesWithResults = (
  samples: GetSampleExtendedDto[],
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  action: () => void,
) => {
  const samplesWithResults: SampleWithResult[] = [];

  for (const s of samples) {
    const result = GetExamResultDtoFromJSON(s.result);
    const attFAD = AttachmentContext.forAssociatedDocuments;
    let attachments = attFAD(snrwb, notifications, [], action);
    if (result) {
      result.sample = s.sample;
      attachments = attFAD(snrwb, notifications, s.attachments, action);
      attachments.new = () => {
        const attachment = AttachmentContext.newAttachment();
        attachment.enObjectType = AttachmentContext.ObjectType.ExamResult;
        attachment.objectId = result.id;
        attachment.typeId = s.attachmentType;
        return attachment;
      };
    }
    samplesWithResults.push({
      sample: s.sample,
      proceeding: s.proceeding
        ? GetProceedingDtoFromJSON(s.proceeding)
        : undefined,
      result: result,
      resultAttachments: attachments,
    });
  }

  return samplesWithResults;
};

const getSamples = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  sampleExamId: string,
  action: () => void,
) => {
  const samples = await snrwb.samples.getBySampleExamId(sampleExamId);
  return {
    samples: getSamplesWithResults(samples, snrwb, notifications, action),
  };
};

export const getFeatures = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  sampleExamId: string,
  action: () => void,
) => {
  if (sampleExamId === 'yes, lint, I love you') {
    action();
  }

  return {
    features: await snrwb.essentialFeatures.getBySampleExam(sampleExamId),
  };
};

export const forSampleExamView = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const viewModel = await snrwb.sampleExams.getViewModel(id);

  const attFAD = AttachmentContext.forAssociatedDocuments;
  const insFAD = InspectorContext.forAssociatedDocuments;
  const judFAD = JudgmentContext.forAssociatedDocuments;

  const creator = InspectorContext.creatorForSampleExam(id);

  return {
    exam: viewModel.sampleExam as unknown as GetSampleExamDto,
    approvedExams: viewModel.approvedExams as boolean,
    samples: getSamplesWithResults(
      viewModel.samples as unknown as GetSampleExtendedDto[],
      snrwb,
      notifications,
      action,
    ),
    inspectedProducts:
      viewModel.inspectedProducts as unknown as GetInspectedProductDto[],
    inspectors: insFAD(
      snrwb,
      notifications,
      viewModel.inspectors as unknown as GetInspectorDto[],
      creator,
      action,
    ),
    samplesAttachments: attFAD(
      snrwb,
      notifications,
      viewModel.attachments as unknown as GetAttachmentDto[],
      action,
    ),
    judgments: judFAD(
      snrwb,
      notifications,
      viewModel.judgments as unknown as GetJudgmentDto[],
      action,
    ),
    paks: viewModel.paks as unknown as GetPakDto[],
    features:
      viewModel.essentialFeatures as unknown as GetEssentialFeatureDto[],
  };
};

export interface SampleExamApi {
  edit: (dto: CreateUpdateSampleExamDto) => void;
  sampleCollectEdit: (dto: CreateUpdateSampleCollectDto) => void;
  sign: () => void;
  revertSign: () => void;
  readyToSign: (id: string) => Promise<ValidationStatus>;
  mayRevertSigning: (id: string) => Promise<ValidationStatus>;
  mayDelete: (id: string) => Promise<ValidationStatus>;
  delete: () => void;
  mayFinish: (id: string) => Promise<ValidationStatus>;
  finish: () => void;
  changeProductType: (productType: CreateUpdateProductTypeDto) => void;
  approveProductType: (status: ValidationStatus) => void;
  revertApproveProductType: () => void;
  mayProductTypeBeApproved: (id: string) => Promise<ValidationStatus>;
  addOrganization: (dto: CreateUpdateOrganizationDto) => void;
  addSupplier: (dto: CreateUpdateOrganizationDto) => void;
  sampleExamProtocol: () => void;
  addSample: (dto: CreateUpdateSampleDto) => void;
  updateSample: (id: string, dto: CreateUpdateSampleDto) => void;
  deleteSample: (id: string) => void;
  addFeature: (dto: CreateUpdateEssentialFeatureDto) => void;
  updateFeature: (id: string, dto: CreateUpdateEssentialFeatureDto) => void;
  deleteFeature: (id: string) => void;
  addResult: (sampleId: string) => void;
  updateResult: (id: string, dto: CreateUpdateExamResultDto) => void;
  mayBeApprovedResult: (id: string) => Promise<ValidationStatus>;
  mayBePublishedResult: (id: string) => Promise<ValidationStatus>;
  approveResult: (id: string) => void;
  revertApproveResult: (id: string, reason?: string) => void;
  publishResult: (id: string) => void;
  revertPublishResult: (id: string) => void;
  generateResultDocument: (sampleId: string) => void;
  deleteResult: (id: string) => void;
  revertControlSampleExam: (id: string) => void;
  addInspection: AddInspectionFunc;
  addPak: (organizationId: string) => void;
  refresh: () => void;
}

export const handlersForSampleExamView = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  sampleExam: GetSampleExamDto,
  organizationalUnitId: string | undefined,
  refreshAction: (generator?: ModelGeneratorFunc) => void,
  navigateCollectAction: (
    sampleCollectId: string,
    newWindow?: boolean,
    tab?: string,
  ) => void,
  inspectionAction: (inspectionId: string) => void,
  pakAction: (pakId: string) => void,
) => {
  const collectId = sampleExam?.sampleCollect?.id;
  const refS = () => refreshAction(getSamples);

  return {
    edit: (dto: CreateUpdateSampleExamDto) =>
      notifications.onPromise(
        snrwb.sampleExams.update(sampleExam.id, dto),
        () => refreshAction(getSampleExam),
      ),
    sampleCollectEdit: (dto: CreateUpdateSampleCollectDto) =>
      notifications.onPromise(snrwb.sampleCollects.update(collectId, dto), () =>
        refreshAction(getSampleExam),
      ),

    sign: () =>
      notifications.onPromise(snrwb.sampleExams.sign(sampleExam.id), () =>
        refreshAction(getSampleExam),
      ),

    revertSign: () =>
      notifications.onPromise(snrwb.sampleExams.revertSign(sampleExam.id), () =>
        refreshAction(getSampleExam),
      ),

    readyToSign: (id: string) => snrwb.sampleExams.readyToSign(id),
    mayRevertSigning: (id: string) => snrwb.sampleExams.mayRevertSigning(id),
    mayDelete: (id: string) => snrwb.sampleExams.mayDelete(id),

    delete: () =>
      notifications.onPromise(snrwb.sampleExams.delete(sampleExam.id), () =>
        navigateCollectAction(sampleExam.sampleCollect.id, false, 'products'),
      ),

    mayFinish: (id: string) => snrwb.sampleExams.mayFinish(id),

    finish: () => {
      const dto = toCuDto(sampleExam);
      dto.completionDate = !dto.completionDate ? new Date() : null;
      notifications.onPromise(
        snrwb.sampleExams.update(sampleExam.id, dto),
        () => refreshAction(getSampleExam),
      );
    },

    changeProductType: (dto: CreateUpdateProductTypeDto) =>
      notifications.onPromise(
        snrwb.productTypes.update(sampleExam.productType.id, dto),
        () => refreshAction(getSampleExam),
      ),

    approveProductType: (status: ValidationStatus) =>
      notifications.onPromise(
        snrwb.productTypes.approve(sampleExam.productType.id, status),
        () => refreshAction(getSampleExam),
      ),

    revertApproveProductType: () => {
      notifications.onPromise(
        snrwb.productTypes.revertApprove(sampleExam.productType.id),
        () => refreshAction(getSampleExam),
      );
    },

    mayProductTypeBeApproved: (id: string) =>
      snrwb.productTypes.mayBeApproved(id),

    addOrganization: (dto: CreateUpdateOrganizationDto) => {
      notifications.onPromise(
        snrwb.sampleCollects.updateWithNewOrganization(collectId, dto),
        () => refreshAction(getSampleExam),
      );
    },

    addSupplier: (dto: CreateUpdateOrganizationDto) => {
      notifications.onPromise(
        snrwb.sampleExams.updateWithNewSupplier(sampleExam.id, dto),
        () => refreshAction(getSampleExam),
      );
    },

    sampleExamProtocol: () =>
      notifications.onPromise(
        snrwb.printouts.sampleExamProtocol(sampleExam.id),
      ),

    addSample: (dto: CreateUpdateSampleDto) => {
      dto.sampleExamId = sampleExam.id;
      if (organizationalUnitId) {
        dto.operateOrganizationalUnitId = organizationalUnitId;
      }
      notifications.onPromise(snrwb.samples.createVoid(dto), refS);
    },

    updateSample: (id: string, dto: CreateUpdateSampleDto) =>
      notifications.onPromise(snrwb.samples.update(id, dto), refreshAction),

    deleteSample: (id: string) =>
      notifications.onPromise(snrwb.samples.delete(id), refreshAction),

    addFeature: (dto: CreateUpdateEssentialFeatureDto) => {
      dto.sampleExamId = sampleExam.id;
      notifications.onPromise(
        snrwb.essentialFeatures.create(dto).then(() => {
          false;
        }),
        () => refreshAction(getFeatures),
      );
    },

    updateFeature: (id: string, dto: CreateUpdateEssentialFeatureDto) =>
      notifications.onPromise(snrwb.essentialFeatures.update(id, dto), () =>
        refreshAction(getFeatures),
      ),

    deleteFeature: (id: string) =>
      notifications.onPromise(snrwb.essentialFeatures.delete(id), () =>
        refreshAction(getFeatures),
      ),

    addResult: (sampleId: string) => {
      const dto = CreateUpdateExamResultDtoFromJSON({
        sampleId: sampleId,
        negative: false,
      });
      notifications.onPromise(snrwb.examResults.createVoid(dto), refS);
    },

    updateResult: (id: string, dto: CreateUpdateExamResultDto) =>
      notifications.onPromise(snrwb.examResults.update(id, dto), refS),

    approveResult: (id: string) =>
      notifications.onPromise(snrwb.examResults.approve(id), refS),

    revertApproveResult: (id: string, reason?: string) =>
      notifications.onPromise(
        snrwb.examResults.revertApprove(id, reason),
        refreshAction,
      ),

    publishResult: (id: string) =>
      notifications.onPromise(snrwb.examResults.publish(id), refS),

    revertPublishResult: (id: string) =>
      notifications.onPromise(snrwb.examResults.revertPublish(id), refS),

    mayBeApprovedResult: (id: string) => snrwb.examResults.mayBeApproved(id),

    mayBePublishedResult: (id: string) => snrwb.examResults.mayBePublished(id),

    deleteResult: (id: string) =>
      notifications.onPromise(snrwb.examResults.delete(id), refS),

    generateResultDocument: (sampleId: string) =>
      notifications.onPromise(snrwb.printouts.resultDocument(sampleId)),

    revertControlSampleExam: (id: string) =>
      notifications.onPromise(
        snrwb.proceedings.revertControlSampleExam(id),
        refreshAction,
      ),

    addInspection: handlerForAddInspection(snrwb, inspectionAction),

    addPak: (sampleId: string) =>
      notifications.onPromise(
        snrwb.paks.createBasedOnSample(sampleId).then(pak => pakAction(pak.id)),
      ),

    refresh: () => refreshAction(),
  };
};

const add = (
  form: { name: string; value: React.ReactNode }[],
  object: unknown,
  label: string,
  value: () => React.ReactNode,
) => {
  if (object) {
    form.push({ name: label, value: value() });
  }
};

export const detailsPresent = (sampleExam: GetSampleExamDto) => {
  const form: { name: string; value: string }[] = [];

  add(
    form,
    sampleExam.fileNumber,
    'Numer akt badania',
    () => sampleExam.fileNumber,
  );

  add(form, sampleExam.organizationType, 'Miejsce pobrania', () =>
    DictionaryStatic({ value: sampleExam.organizationType }),
  );
  add(form, sampleExam.witnesses, 'W obecności', () => sampleExam.witnesses);
  add(form, sampleExam.collectDate, 'Data pobrania', () =>
    momencik(sampleExam.collectDate),
  );
  add(
    form,
    sampleExam.expirationText,
    'Termin ważności',
    () => sampleExam.expirationText,
  );
  add(form, sampleExam.expirationNA, 'Termin ważności', () => 'Nie dotyczy');
  add(form, sampleExam.expirationDate, 'Termin ważności', () =>
    momencik(sampleExam.expirationDate),
  );
  add(
    form,
    sampleExam.technicalSpecification,
    'Specyfikacja',
    () => sampleExam.technicalSpecification,
  );
  add(form, sampleExam.supplierUnknown, 'Dostawca', () => 'Nie ustalono');
  add(form, sampleExam.supplierNA, 'Dostawca', () => 'Nie dotyczy');
  add(form, sampleExam.supplier, 'Dostawca', () => sampleExam.supplier.name);
  add(form, sampleExam.supplyDate, 'Data dostawy', () =>
    momencik(sampleExam.supplyDate),
  );
  add(form, sampleExam.euProjectType, 'Projekt UE', () =>
    sampleExam.euProjectType ? sampleExam.euProjectType.value : 'Nie dotyczy',
  );
  add(
    form,
    sampleExam.invoiceNumber,
    'Rachunek dostawy',
    () => sampleExam.invoiceNumber,
  );
  add(
    form,
    sampleExam.invoiceNumberNA,
    'Rachunek dostawy',
    () => 'Nie dotyczy',
  );
  add(
    form,
    sampleExam.securedQuantity,
    'Wielkość partii, z której pobrano próbkę',
    () => sampleExam.securedQuantity,
  );
  add(
    form,
    sampleExam.additionalInfo,
    'Informacje dodatkowe',
    () => sampleExam.additionalInfo,
  );
  add(form, sampleExam.comments, 'Uwagi', () => sampleExam.comments);
  return form;
};

export const regulationsPresent = (se: GetSampleExamDto) => {
  const form: { name: string; value: React.ReactNode }[] = [];

  add(form, se.regulationIds || se.regulations, 'Przepisy', () =>
    UnorderedList({ values: se.regulationValues, freeValue: se.regulations }),
  );

  return form;
};

export const signingPresent = (se: GetSampleExamDto) => {
  const form: { name: string; value: string }[] = [];

  add(form, se.protocolNumber, 'Numer protokołu', () => se.protocolNumber);
  add(form, se.repairMethod, 'Omówienie poprawek', () => se.repairMethod);
  add(
    form,
    se.reasonNotTakingControlSample,
    'Przyczyna niepobrania próbki kontrolnej',
    () => se.reasonNotTakingControlSample,
  );
  add(
    form,
    se.protocolPrintMode,
    'Tryb sporządzenia protokołu',
    () => se.protocolPrintMode.value,
  );
  add(form, se.sampleSignatureDate, 'Data podpisania protokołu', () =>
    momencik(se.sampleSignatureDate),
  );
  add(form, se.refusalReason, 'Powód odmowy', () => se.refusalReason);

  return form;
};

export const protocolPresent = (se: GetSampleExamDto) => {
  const form: { name: string; value: string }[] = [];

  add(form, se.findings, 'Ustalenia', () =>
    TextOrControl({ readonly: true, value: se.findings }),
  );
  add(
    form,
    se.reasonNotTakingControlSample,
    'Przyczyna niepobrania próbki kontrolnej',
    () => se.reasonNotTakingControlSample,
  );
  add(form, se.protocolNumber, 'Numer protokołu', () => se.protocolNumber);

  return form;
};
