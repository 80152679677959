/*eslint complexity: ["error", 20]*/
import React from 'react';
import Form from 'react-bootstrap/Form';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateEssentialFeatureDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import TextOrControl from '../../../app/components/TextOrControl';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';

// eslint-disable-next-line complexity
export const EssentialFeature = ({
  feature,
  examApproved,
  sampleIsPublished,
  resultSampleExists,
  controlSampleExists,
  controlSampleIsPublished,
  resultControlSampleExists,
  editAuthorized,
  publishAuthorized,
  finishAndResultExists,
  propertyChange,
}: {
  feature: CreateUpdateEssentialFeatureDto;
  examApproved: boolean;
  sampleIsPublished: boolean;
  resultSampleExists: boolean;
  controlSampleExists: boolean;
  controlSampleIsPublished: boolean;
  resultControlSampleExists: boolean;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  finishAndResultExists: boolean;
  propertyChange: (obj: Partial<CreateUpdateEssentialFeatureDto>) => void;
}) => {
  const snrwb = useSnrwbCore();

  const anythingPublished =
    sampleIsPublished || (controlSampleExists && controlSampleIsPublished);

  const readonlyAuthorized = !(
    (!examApproved && editAuthorized) ||
    (!anythingPublished && publishAuthorized && finishAndResultExists)
  );
  const publishReadonly =
    anythingPublished ||
    (publishAuthorized &&
      !editAuthorized &&
      !resultSampleExists &&
      !resultControlSampleExists);
  const samplePublishReadonly =
    sampleIsPublished ||
    (publishAuthorized && !editAuthorized && !resultSampleExists);
  const controlSamplePublishReadonly =
    !controlSampleExists ||
    controlSampleIsPublished ||
    (publishAuthorized && !editAuthorized && !resultControlSampleExists);

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="type" label="Zasadnicza charakterystyka">
          <TextOrControl readonly={readonlyAuthorized} value={feature.feature}>
            <CreatableClearableSelector
              value={feature.feature}
              onChange={option => propertyChange({ feature: option.label })}
              provider={snrwb.essentialFeatures.getFeaturesByPattern}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="type" label="Właściwość użytkowa">
          <TextOrControl
            readonly={readonlyAuthorized}
            value={feature.functionalProperty}
          >
            <CreatableClearableSelector
              value={feature.functionalProperty}
              readOnly={readonlyAuthorized}
              onChange={option =>
                propertyChange({ functionalProperty: option.label })
              }
              provider={
                snrwb.essentialFeatures.getFunctionalPropertiesByPattern
              }
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="type" label="Metoda badania">
          <TextOrControl
            readonly={readonlyAuthorized}
            value={feature.examMethod}
          >
            <CreatableClearableSelector
              value={feature.examMethod}
              onChange={option => propertyChange({ examMethod: option.label })}
              provider={snrwb.essentialFeatures.getExamMethodsByPattern}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="expectedResult" label="Deklarowana wartość">
          <TextOrControl
            readonly={readonlyAuthorized}
            value={feature.expectedResult}
          >
            <RichTextSingleLine
              value={feature.expectedResult}
              onChange={value => propertyChange({ expectedResult: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="publish" label="Publikować wynik">
          <BooleanDropdown
            canBeUndefined
            readOnly={publishReadonly}
            value={feature.publish}
            onChange={value => propertyChange({ publish: value })}
          />
        </FormRow>
        <FormRow controlId="result" label="Wynik próbki">
          <TextOrControl
            readonly={samplePublishReadonly}
            value={feature.result}
          >
            <RichTextSingleLine
              value={feature.result || ''}
              onChange={value => propertyChange({ result: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow
          controlId="resultUncertainty"
          label="Niepewność wyniku badania próbki"
        >
          <TextOrControl
            readonly={samplePublishReadonly}
            value={feature.result}
          >
            <RichTextSingleLine
              value={feature.resultUncertainty || ''}
              onChange={value => propertyChange({ resultUncertainty: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="fullfilled" label="Próbka spełnia charakterystykę">
          <BooleanDropdown
            canBeUndefined
            readOnly={samplePublishReadonly}
            value={feature.fullfilled}
            onChange={value => propertyChange({ fullfilled: value })}
          />
        </FormRow>
        <FormRow controlId="controlResult" label="Wynik próbki kontrolnej">
          <TextOrControl
            readonly={controlSamplePublishReadonly}
            value={feature.controlResult}
          >
            <RichTextSingleLine
              value={feature.controlResult || ''}
              onChange={value => propertyChange({ controlResult: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow
          controlId="controlResultUncertainty"
          label="Niepewność wyniku badania próbki kontrolnej"
        >
          <TextOrControl
            readonly={controlSamplePublishReadonly}
            value={feature.controlResult}
          >
            <RichTextSingleLine
              value={feature.controlResultUncertainty || ''}
              onChange={value =>
                propertyChange({ controlResultUncertainty: value })
              }
            />
          </TextOrControl>
        </FormRow>
        <FormRow
          controlId="controlFullfilled"
          label="Próbka kontrolna spełnia charakterystykę"
        >
          <BooleanDropdown
            canBeUndefined
            readOnly={controlSamplePublishReadonly}
            value={feature.controlFullfilled}
            onChange={value => propertyChange({ controlFullfilled: value })}
          />
        </FormRow>
      </Form>
    </>
  );
};
