/*eslint max-lines-per-function: ["error", 200]*/
import {
  FilterReportData,
  FilterReportYearData,
} from '../../../snrwb/components/Reports/Filter';
import { FilterDatesProductTypeReportData } from '../../../snrwb/components/Reports/FilterDatesProductType';
import {
  ReportCorrectiveActionsType,
  ReportCostSampleExamsType,
  ReportInspected,
  ReportInspectedOrganizations,
  ReportInspectedProductsByRangeType,
  ReportInspectedProductsBySignType,
  ReportInspectedProductsByTypeType,
  ReportInspectionPlanForInspectedProductType,
  ReportInspectionPlanForOrganizationType,
  ReportJudgmentsCountType,
  ReportOrderedSampleExamsType,
  ReportPakAmountType,
  ReportPakJudgmentType,
  ReportProceedingType,
  ReportProductsByMisstatement,
  ReportProductsGroupsType,
  ReportPublicationsType,
} from '../../types/ReportTypes';
import {
  FilterDatesProductTypeReportData as AutogeneratedFilterDatesProductTypeReportData,
  InspectionPlanProgressDataForChartType,
  ReportCustomsFeedbacksType,
  TimelineEventType,
  TimelineProcessType,
  TimelineProcessTypeObjectStartTypeEnum,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';

export interface ReportContextInterface {
  reportConductedInspections: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspected>;

  reportInspectedProducts: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspected>;

  reportInspectedOrganizations: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspectedOrganizations>;

  reportInspectedOrganizationsByProducts: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspectedOrganizations>;

  reportInspectedOrganizationsByProductsWithMisstatement: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspectedOrganizations>;

  reportProductsBySign: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspectedProductsBySignType>;

  reportProductsByType: (
    filter?: FilterDatesProductTypeReportData,
  ) => Promise<ReportInspectedProductsByTypeType>;

  reportProductsByRange: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportInspectedProductsByRangeType>;

  reportProductsByMisstatement: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportProductsByMisstatement>;

  reportOrderedSampleExams: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportOrderedSampleExamsType>;

  reportJudgmentsCount: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportJudgmentsCountType>;

  reportJudgmentsInProceedingAndPak: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportJudgmentsCountType>;

  reportCustomsFeedback: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportCustomsFeedbacksType>;

  reportCorrectiveAction: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportCorrectiveActionsType>;

  reportPakByJudgment: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportPakJudgmentType>;

  reportPakAmount: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportPakAmountType>;

  reportProceeding: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportProceedingType>;

  reportProductsGroup: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportProductsGroupsType>;

  reportCostSampleExams: (
    onlyCpr: boolean,
    filter?: FilterReportData,
  ) => Promise<ReportCostSampleExamsType>;

  reportInspectionPlanByOrganization: (
    filter?: FilterReportData,
  ) => Promise<ReportInspectionPlanForOrganizationType>;

  reportInspectionPlanByInspectedProduct: (
    filter?: FilterReportData,
  ) => Promise<ReportInspectionPlanForInspectedProductType>;

  reportInspectionPlanProgressByOrganization: (
    filter?: FilterReportData,
  ) => Promise<InspectionPlanProgressDataForChartType>;

  reportInspectionPlanProgressByInspectedProducts: (
    filter?: FilterReportData,
  ) => Promise<InspectionPlanProgressDataForChartType>;

  reportInspectionPlanProgressByInspectedProductsCpr: (
    filter?: FilterReportData,
  ) => Promise<InspectionPlanProgressDataForChartType>;

  reportInspectionPlanProgressByInspectedProductGroups: (
    filter?: FilterReportData,
  ) => Promise<InspectionPlanProgressDataForChartType>;

  reportPublications: (
    filter?: FilterReportData,
  ) => Promise<ReportPublicationsType[]>;

  getTimelineByProductType: (
    productTypeId: string,
  ) => Promise<TimelineProcessType[]>;

  getTimelineBySampleExam: (
    SampleExamId: string,
  ) => Promise<TimelineProcessType[]>;

  getTimelineByProcess: (
    objectId: string,
    objectType: TimelineProcessTypeObjectStartTypeEnum,
  ) => Promise<TimelineEventType[]>;
}

export const ReportContext = (api: DefaultApi) => {
  return {
    reportConductedInspections: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportConductedInspections({
        onlyCpr,
        ...filter,
      }) as Promise<ReportInspected>,

    reportInspectedProducts: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportInspectedProducts({
        onlyCpr,
        ...filter,
      }) as Promise<ReportInspected>,

    reportInspectedOrganizations: (
      onlyCpr: boolean,
      filter?: FilterReportData,
    ) =>
      api.reportControllerReportInspectedOrganizations({
        onlyCpr,
        ...filter,
      }) as Promise<ReportInspectedOrganizations>,

    reportInspectedOrganizationsByProducts: (
      onlyCpr: boolean,
      filter?: FilterReportData,
    ) =>
      api.reportControllerReportInspectedOrganizationsByProducts({
        onlyCpr,
        ...filter,
      }) as Promise<ReportInspectedOrganizations>,

    reportInspectedOrganizationsByProductsWithMisstatement: (
      onlyCpr: boolean,
      filter?: FilterReportData,
    ) =>
      api.reportControllerReportInspectedOrganizationsByProductsWithMisstatement(
        {
          onlyCpr,
          ...filter,
        },
      ) as Promise<ReportInspectedOrganizations>,

    reportProductsBySign: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportProductsBySign({
        onlyCpr,
        ...filter,
      }) as Promise<ReportInspectedProductsBySignType>,

    reportProductsByType: (filter?: FilterDatesProductTypeReportData) =>
      api.reportControllerReportProductsByType(
        filter as AutogeneratedFilterDatesProductTypeReportData,
      ) as Promise<ReportInspectedProductsByTypeType>,

    reportProductsByRange: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportProductsByRange({
        onlyCpr,
        ...filter,
      }) as Promise<ReportInspectedProductsByRangeType>,

    reportProductsByMisstatement: (
      onlyCpr: boolean,
      filter?: FilterReportData,
    ) =>
      api.reportControllerReportProductsByMisstatement({
        onlyCpr,
        ...filter,
      }) as Promise<ReportProductsByMisstatement>,

    reportOrderedSampleExams: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportOrderedSampleExams({
        onlyCpr,
        ...filter,
      }) as Promise<ReportOrderedSampleExamsType>,

    reportJudgmentsCount: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportJudgmentsCount({
        onlyCpr,
        ...filter,
      }) as Promise<ReportJudgmentsCountType>,

    reportJudgmentsInProceedingAndPak: (
      onlyCpr: boolean,
      filter?: FilterReportData,
    ) =>
      api.reportControllerReportJudgmentsInProceedingAndPak({
        onlyCpr,
        ...filter,
      }) as Promise<ReportJudgmentsCountType>,

    reportCustomsFeedback: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportCustomsFeedbacks({
        onlyCpr,
        ...filter,
      }) as Promise<ReportCustomsFeedbacksType>,

    reportCorrectiveAction: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportCorrectiveActions({
        onlyCpr,
        ...filter,
      }) as Promise<ReportCorrectiveActionsType>,

    reportPakByJudgment: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportPakByJudgment({
        onlyCpr,
        ...filter,
      }) as Promise<ReportPakJudgmentType>,

    reportPakAmount: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportPakAmount({
        onlyCpr,
        ...filter,
      }) as Promise<ReportPakAmountType>,

    reportProceeding: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportProceeding({
        onlyCpr,
        ...filter,
      }) as Promise<ReportProceedingType>,

    reportProductsGroup: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportProductsGroups({
        onlyCpr,
        ...filter,
      }) as Promise<ReportProductsGroupsType>,

    reportCostSampleExams: (onlyCpr: boolean, filter?: FilterReportData) =>
      api.reportControllerReportCostSampleExams({
        onlyCpr,
        ...filter,
      }) as Promise<ReportCostSampleExamsType>,

    reportInspectionPlanByOrganization: (filter?: FilterReportYearData) =>
      api.reportControllerReportInspectionPlanByOrganization({
        ...filter,
      }) as Promise<ReportInspectionPlanForOrganizationType>,

    reportInspectionPlanByInspectedProduct: (filter?: FilterReportYearData) =>
      api.reportControllerReportInspectionPlanByInspectedProduct({
        ...filter,
      }) as Promise<ReportInspectionPlanForInspectedProductType>,

    reportPublications: (filter?: FilterReportData) =>
      api.reportControllerReportPublications({
        onlyCpr: true,
        ...filter,
      }) as Promise<ReportPublicationsType[]>,

    getTimelineByProductType: (productTypeId: string) =>
      api.reportControllerGetTimelineByProductType(productTypeId) as Promise<
        TimelineProcessType[]
      >,

    getTimelineBySampleExam: (sampleExamId: string) =>
      api.reportControllerGetTimelineBySampleExam(sampleExamId) as Promise<
        TimelineProcessType[]
      >,

    getTimelineByProcess: (
      objectId: string,
      objectType: TimelineProcessTypeObjectStartTypeEnum,
    ) =>
      api.reportControllerGetTimelineByProcess(objectId, objectType) as Promise<
        TimelineEventType[]
      >,
    reportInspectionPlanProgressByOrganization: (
      filter?: FilterReportYearData,
    ) =>
      api.reportControllerReportInspectionPlanProgressByOrganization({
        ...filter,
      }) as Promise<InspectionPlanProgressDataForChartType>,

    reportInspectionPlanProgressByInspectedProducts: (
      filter?: FilterReportYearData,
    ) =>
      api.reportControllerReportInspectionPlanProgressByInspectedProduct({
        ...filter,
      }) as Promise<InspectionPlanProgressDataForChartType>,

    reportInspectionPlanProgressByInspectedProductsCpr: (
      filter?: FilterReportYearData,
    ) =>
      api.reportControllerReportInspectionPlanProgressByInspectedProductCpr({
        ...filter,
      }) as Promise<InspectionPlanProgressDataForChartType>,

    reportInspectionPlanProgressByInspectedProductGroups: (
      filter?: FilterReportYearData,
    ) =>
      api.reportControllerReportInspectionPlanProgressByInspectedProductGroup({
        ...filter,
      }) as Promise<InspectionPlanProgressDataForChartType>,
  };
};
