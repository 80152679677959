/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineProcessType
 */
export interface TimelineProcessType {
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    processType: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    place: string;
    /**
     * 
     * @type {Date}
     * @memberof TimelineProcessType
     */
    dateOfStart?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    objectStartType: TimelineProcessTypeObjectStartTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    objectStartId: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineProcessType
     */
    organizationalUnit: string;
}

export function TimelineProcessTypeFromJSON(json: any): TimelineProcessType {
    return TimelineProcessTypeFromJSONTyped(json, false);
}

export function TimelineProcessTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineProcessType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processType': json['processType'],
        'number': json['number'],
        'place': json['place'],
        'dateOfStart': !exists(json, 'dateOfStart') ? undefined : (json['dateOfStart'] === null ? null : new Date(json['dateOfStart'])),
        'objectStartType': json['objectStartType'],
        'objectStartId': json['objectStartId'],
        'parentId': json['parentId'],
        'organizationalUnit': json['organizationalUnit'],
    };
}

export function TimelineProcessTypeToJSON(value?: TimelineProcessType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processType': value.processType,
        'number': value.number,
        'place': value.place,
        'dateOfStart': value.dateOfStart === undefined ? undefined : (value.dateOfStart === null ? null : value.dateOfStart.toISOString()),
        'objectStartType': value.objectStartType,
        'objectStartId': value.objectStartId,
        'parentId': value.parentId,
        'organizationalUnit': value.organizationalUnit,
    };
}

/**
* @export
* @enum {string}
*/
export enum TimelineProcessTypeObjectStartTypeEnum {
    InspectedProduct = 'InspectedProduct',
    SampleExam = 'SampleExam',
    Inspection = 'Inspection',
    Metric = 'Metric',
    JudgmentFromInspection = 'JudgmentFromInspection',
    JudgmentFromProceeding = 'JudgmentFromProceeding',
    JudgmentFromPak = 'JudgmentFromPak',
    JudgmentFromSampleExam = 'JudgmentFromSampleExam',
    Proceeding = 'Proceeding',
    Pak = 'Pak',
    Sample = 'Sample',
    ExamResult = 'ExamResult',
    CorrectiveActionFromInspection = 'CorrectiveActionFromInspection',
    CorrectiveActionFromProceeding = 'CorrectiveActionFromProceeding'
}


