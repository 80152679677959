/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EssentialFutureType
 */
export interface EssentialFutureType {
    /**
     * 
     * @type {string}
     * @memberof EssentialFutureType
     */
    feature: string;
    /**
     * 
     * @type {string}
     * @memberof EssentialFutureType
     */
    functionalProperty: string;
    /**
     * 
     * @type {string}
     * @memberof EssentialFutureType
     */
    examMethod: string;
    /**
     * 
     * @type {boolean}
     * @memberof EssentialFutureType
     */
    result: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EssentialFutureType
     */
    controlResult: boolean;
}

export function EssentialFutureTypeFromJSON(json: any): EssentialFutureType {
    return EssentialFutureTypeFromJSONTyped(json, false);
}

export function EssentialFutureTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EssentialFutureType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feature': json['feature'],
        'functionalProperty': json['functionalProperty'],
        'examMethod': json['examMethod'],
        'result': json['result'],
        'controlResult': json['controlResult'],
    };
}

export function EssentialFutureTypeToJSON(value?: EssentialFutureType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feature': value.feature,
        'functionalProperty': value.functionalProperty,
        'examMethod': value.examMethod,
        'result': value.result,
        'controlResult': value.controlResult,
    };
}


