import React from 'react';
import { Chrono } from 'react-chrono';

import momencik from '../../../common/momencik';
import { TimelineProcessType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { TimelineEventView } from '../../containers/ProductTypesView/TimelineEventView';

interface TimelineProps {
  processes: TimelineProcessType[];
  hideEvents?: boolean;
}

export const Timeline: React.FC<TimelineProps> = props => {
  const items = props.processes.map(p => ({
    title: p.processType,
    startId: p.objectStartId,
    objectStartType: p.objectStartType,
    processTitle: momencik(p.dateOfStart),
    processSubtitle: p.number,
    processDetails: p.place,
    organizationalUnit: p.organizationalUnit,
    parentId: p.parentId,
  }));

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Chrono
        disableNavOnKey
        allowDynamicUpdate
        items={items}
        mode="VERTICAL"
        cardHeight={30}
        hideControls={true}
        useReadMore={false}
      >
        {items.map((i, idx) => (
          <div key={idx} style={{ width: '100%' }}>
            <h3>{i.processTitle}</h3>
            <h5>{i.processSubtitle}</h5>
            {i.organizationalUnit && <h6>{i.organizationalUnit}</h6>}
            <p>{i.processDetails}</p>
            {!props.hideEvents && (
              <TimelineEventView
                id={i.startId}
                type={i.objectStartType}
                parentId={i.parentId}
              />
            )}
          </div>
        ))}
      </Chrono>
    </div>
  );
};
